// MarioGame.tsx

import './Mario.css';
import React, { useEffect, useRef, useCallback } from 'react';

const MarioGame: React.FC = () => {
  const playerRef = useRef<HTMLDivElement | null>(null);

  interface Powerup {
    audio: HTMLAudioElement;
    play: () => void;
  }

  const powerup: Powerup = {
    audio: new Audio('http://themushroomkingdom.net/sounds/wav/smb/smb_powerup.wav'),
    play: function () {
      this.audio.currentTime = 0;
      this.audio.play();
    },
  };

  // Update the character's location
  function updateElement(element: HTMLElement, incx: number, incy: number) {
    let x = Number(element.getAttribute('data-x')) + incx;
    let y = Number(element.getAttribute('data-y')) + incy;

    // Check the boundaries to prevent walking off the screen
    const boundaryX = window.innerWidth - 100;
    const boundaryY = 200;
    const maxX = boundaryX - element.clientWidth;
    const maxY = boundaryY - element.clientHeight;

    if (x < 0) {
      x = 0;
    } else if (x > maxX) {
      x = maxX;
    }

    if (y < 0) {
      y = 0;
    } else if (y > maxY) {
      y = maxY;
    }

    element.style.transform = 'translate(' + x + 'px, ' + y + 'px)';

    if (element.classList.contains('mirror')) element.style.transform += ' scaleX(-1)';
    if (element.classList.contains('big')) element.style.transform += ' scale(2)';

    // Update HTML
    element.setAttribute('data-x', x.toString());
    element.setAttribute('data-y', y.toString());
  }

  // When the user presses a key
  const handleKeyDown = useCallback((e: KeyboardEvent) => {
    const player = playerRef.current;
    if (!player) return;

    const speed = 10;

    // Right arrow key
    if (e.key === 'ArrowRight' || e.key === 'd') {
      player.classList.add('caminar');
      player.classList.remove('mirror');
      updateElement(player, +speed, 0);
    }
    // Left arrow key
    else if (e.key === 'ArrowLeft' || e.key === 'a') {
      player.classList.add('caminar');
      player.classList.add('mirror');
      updateElement(player, -speed, 0);
    }

    if (e.key === 'u') {
      player.classList.toggle('big');
      powerup.play();
      updateElement(player, 0, 0);
    }
  }, []); // No dependencies needed as we use ref

  // When the user releases a key
  const handleKeyUp = useCallback(() => {
    const player = playerRef.current;
    if (!player) return;
    player.classList.remove('caminar');
  }, []); // No dependencies needed as we use ref

  useEffect(() => {
    const player = playerRef.current;
    if (!player) return;

    // Initialization
    updateElement(player, 0, 0);

    // Add event listeners
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      // Clean up event listeners when the component is unmounted
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [handleKeyDown, handleKeyUp]); // Include both handlers in dependencies

  return (
    <div
      id="mario"
      ref={playerRef}
      style={{
        width: '58px',
        height: '100px',
        border: '0px solid #000',
        backgroundImage:
          'url(https://cdn.rawgit.com/ManzDev/cursos-assets/gh-pages/css3/mario-sprite.png)',
      }}
    ></div>
  );
};

export default MarioGame;
