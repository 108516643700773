// Random.tsx

import './random.css';
import React from 'react';
import MarioGame from '../components/MarioGame';
import { Helmet } from 'react-helmet';

const Random: React.FC = () => {
  return (
    <div className="random-container">
      <Helmet>
        <title>{'Random | Ben Musoke-Lubega'}</title>
      </Helmet>
      <div className="content">
        <h1>Cool people</h1>
        <p>
          Before you head on out, I recommend checking out the digital homes of the following
          special people:
        </p>
        <ul className="list-container">
          <li>
            <a href="https://coopergyoung.com" target="_blank" rel="noopener noreferrer">
              Cooper Young
            </a>{' '}
            is an accomplished mathematician, cyber security expert, and poet
          </li>
          <li>
            <a href="https://khou22.com" target="_blank" rel="noopener noreferrer">
              Kevin Hou
            </a>{' '}
            is a brilliant software developer, photographer, and entrepreneur
          </li>
          <li>
            <a href="https://ram9828.myportfolio.com" target="_blank" rel="noopener noreferrer">
              Riola Musoke-Lubega
            </a>{' '}
            is a talented designer and user experience researcher
          </li>
        </ul>
      </div>
      <div className="mario-container">
        <MarioGame />
      </div>
    </div>
  );
};

export default Random;
