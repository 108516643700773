// Travel.tsx

import './travel.css';
import React from 'react';
import { Helmet } from 'react-helmet';
import { ComposableMap, Geographies, Geography } from 'react-simple-maps';

interface MapChartProps {}

const geoUrl = '/countries-110m.json';

const MapChart: React.FC<MapChartProps> = () => {
  const highlightedCountries = [
    'United States of America',
    'Netherlands',
    'Italy',
    'Dominican Rep.',
    'Mexico',
    'Puerto Rico',
    'Uganda',
    'Kenya',
    'Ghana',
    'Portugal',
    'United Kingdom',
    'Spain',
    'France',
    'Greece',
  ];

  const [highlightedCounty, setHighlightedCounty] = React.useState<string | null>(null);

  return (
    <div style={{ width: '80vw', overflow: 'hidden', position: 'relative' }}>
      {highlightedCounty && (
        <div
          style={{
            position: 'absolute',
            top: '2%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            color: '#ffffff',
            fontWeight: 'bold',
            padding: '5px',
            borderRadius: '5px',
          }}
        >
          {highlightedCounty}
        </div>
      )}
      <ComposableMap
        style={{
          width: '100%',
          height: 'auto',
          // marginTop: "-5vh",
          // marginBottom: "-50px",
        }}
        projection="geoEquirectangular"
        projectionConfig={{
          scale: 120,
        }}
        viewBox="0 100 800 400"
      >
        <Geographies geography={geoUrl}>
          {({ geographies }) =>
            geographies.map((geo) => {
              const isHighlighted = highlightedCountries.includes(geo.properties.name);

              return (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  onMouseEnter={() => {
                    if (isHighlighted) {
                      setHighlightedCounty(geo.properties.name);
                    }
                  }}
                  onMouseLeave={() => {
                    setHighlightedCounty(null);
                  }}
                  style={{
                    default: {
                      fill: isHighlighted ? '#84a493' : '#D6D6DA',
                      outline: 'none',
                    },
                    hover: {
                      fill: isHighlighted ? '#507e65' : '#D6D6DA',
                      outline: 'none',
                    },
                    pressed: {
                      fill: isHighlighted ? '#507e65' : '#D6D6DA',
                      outline: 'none',
                    },
                  }}
                />
              );
            })
          }
        </Geographies>
      </ComposableMap>
    </div>
  );
};

const Travel: React.FC = () => {
  return (
    <div>
      <Helmet>
        <title>{'Travel | Ben Musoke-Lubega'}</title>
      </Helmet>
      <h1>Travel</h1>
      <p className="caption">Countries I've visited so far</p>
      <MapChart />
      <h2>Why I travel</h2>
      <p>
        Exploring a part of the world I’ve never been to feels like stepping into a brand-new
        library. But instead of walls and shelves brimming with countless books, this library is
        alive—filled with people, nature, foods, music, fashion, and architecture. Simply by
        walking, talking, observing, and listening, I find myself immersed in a holistic, unwritten
        curriculum. These lessons are dense, so it takes weeks or months for them to truly settle
        within my soul. But when they do—when those moments of epiphany and understanding finally
        arrive—I’m deeply grateful for them.
      </p>
      <p>
        Leaving home on an adventure and then returning always seems to add a new layer of
        perspective to my ordinary life. I’m fortunate to have seen as much of the world as I have,
        and I hope to continue expanding that map as I grow. I’m always happy to share stories from
        the places I’ve visited and love receiving recommendations for where to go next.
      </p>
      <p>
        If there’s a place you believe I absolutely must experience and touch with my two little
        feet, feel free to let me know at bprinsta@gmail.com—I’ll gladly add it to the bucket list.
      </p>
    </div>
  );
};

export default Travel;
