// SVGIcon.tsx

import React, { ComponentType, SVGProps } from 'react';
import './SVGIcon.css';

interface IconProps {
  svgComponent: ComponentType<SVGProps<SVGSVGElement> & { title?: string }>;
  color?: string;
  size?: number;
}

const SVGIcon: React.FC<IconProps> = ({
  svgComponent: SvgComponent,
  color = 'currentColor',
  size = 30,
}) => {
  return (
    <span className="svg-icon-container">
      <SvgComponent fill={color} width={size} height={size} />
    </span>
  );
};

export default SVGIcon;
