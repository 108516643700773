// NotFound.tsx
import React from 'react';

const NotFound = () => {
  return (
    <div>
      <h1>404 - Not Found</h1>
      <p>Sorry, the page you are looking for might be in another forest!</p>
    </div>
  );
};

export default NotFound;
