// Travel.tsx

import './travel.css';
import React from 'react';
import { Helmet } from 'react-helmet';
import { ComposableMap, Geographies, Geography } from 'react-simple-maps';

interface MapChartProps {}

const geoUrl = '/countries-110m.json';

const MapChart: React.FC<MapChartProps> = () => {
  const highlightedCountries = [
    'United States of America',
    'Netherlands',
    'Italy',
    'Dominican Rep.',
    'Mexico',
    'Puerto Rico',
    'Uganda',
    'Kenya',
    'Ghana',
    'Portugal',
    'United Kingdom',
    'Spain',
    'France',
    'Greece',
  ];

  return (
    <div style={{ width: '80vw', overflow: 'hidden' }}>
      <ComposableMap
        style={{
          width: '100%',
          height: 'auto',
          // marginTop: "-5vh",
          // marginBottom: "-50px",
        }}
        projection="geoEquirectangular"
        projectionConfig={{
          scale: 120,
        }}
        viewBox="0 100 800 400"
      >
        <Geographies geography={geoUrl}>
          {({ geographies }) =>
            geographies.map((geo) => {
              const isHighlighted = highlightedCountries.includes(geo.properties.name);

              return (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  style={{
                    default: {
                      fill: isHighlighted ? '#84a493' : '#D6D6DA', // Change color for highlighted countries
                      outline: 'none',
                    },
                    hover: {
                      fill: isHighlighted ? '#507e65' : '#D6D6DA',
                      outline: 'none',
                    },
                    pressed: {
                      fill: isHighlighted ? '#507e65' : '#D6D6DA',
                      outline: 'none',
                    },
                  }}
                />
              );
            })
          }
        </Geographies>
      </ComposableMap>
    </div>
  );
};

const Travel: React.FC = () => {
  return (
    <div>
      <Helmet>
        <title>{'Travel | Ben Musoke-Lubega'}</title>
      </Helmet>
      <h1>Travel</h1>
      <p className="caption">Countries I've visited so far</p>
      <MapChart />
      <h2>Why I travel</h2>
      <p>
        Venturing somewhere in the world I've never been to is like visiting a new library. But
        instead of walls and shelves brimming with countless books, this library is filled with a
        diverse set of people, nature, foods, music, fashion, and architecture. By simply walking,
        talking, looking at, and listening to the world around me, I'm effectively cramming in a
        holistic academic curriculum. These courses are utterly dense, so the lessons take several
        weeks and months to properly manifest within my soul. But when I come to these moments of
        epiphany and understanding, I'm so appreciative of them. Often, I've found that leaving home
        on an adventure and returning adds a valuable layer of perspective to my ordinary life.
      </p>
      <p>
        I'm grateful I've had the opportunity to see as much of the world as I have. Hopefully, I
        can keep expanding this map while I continue learning and growing as a person. I'm always
        happy to talk about my experiences of the places I've visited, and I love to receive
        recommendations for places to travel to in the future.
      </p>
    </div>
  );
};

export default Travel;
