// Bio.tsx

import { Helmet } from 'react-helmet';
import './bio.css';
import React from 'react';

const Bio: React.FC = () => {
  return (
    <div>
      <Helmet>
        <title>{'Bio | Ben Musoke-Lubega'}</title>
      </Helmet>
      <div className="img-container">
        <img src="/images/benml-headshot-square.jpg" alt="Portrait of Ben Musoke-Lubega" />
      </div>
      <h1>About Me</h1>
      <p>Hey, I'm Ben 👋</p>
      <p>
        Currently, I'm a software engineer at{' '}
        <a href="https://www.apple.com/" target="_blank" rel="noopener noreferrer">
          Apple
        </a>
        . These days, I primarily develop iOS & macOS apps, frameworks, daemons, and command line
        tools. Previously, I was an iOS engineer at{' '}
        <a href="https://www.joinhandshake.com/" target="_blank" rel="noopener noreferrer">
          Handshake
        </a>
        , creating beautiful and accessible user experiences to help students find meaningful
        careers. Before that, I received a computer science degree from Princeton University.
      </p>
      <p>
        Outside work, I cycle through hobbies as the seasons come. At the moment, I'm passionate
        about running, tennis, writing, cooking, art, and music.
      </p>
    </div>
  );
};

export default Bio;
