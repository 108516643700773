// Navbar.tsx

import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import './Navbar.css';

const Navbar: React.FC = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      setIsScrolled(scrollTop > 0);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <nav className={`navbar ${isScrolled ? 'scrolled' : ''}`}>
      <div className="nav-container">
        <ul>
          <li>
            <NavLink to="/">Home</NavLink>
          </li>
          <li>
            <NavLink to="/bio">Bio</NavLink>
          </li>
          <li>
            <NavLink to="/musings">Musings</NavLink>
          </li>
          <li>
            <NavLink to="/travel">Travel</NavLink>
          </li>
          <li>
            <NavLink to="/random">Random</NavLink>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
