// Home.tsx

import React from 'react';
import { ReactComponent as ReactLogo } from '../svg/plant_logo.svg';
import { Helmet } from 'react-helmet';

const Home: React.FC = () => {
  return (
    <div>
      <Helmet>
        <title>{'Ben Musoke-Lubega'}</title>
      </Helmet>
      <ReactLogo className="App-logo" />
      <h1>Ben Musoke-Lubega</h1>
      <p>a software engineer</p>
    </div>
  );
};

export default Home;
