// Musings.tsx

import './musings.css';
import React from 'react';
import { Link } from 'react-router-dom';
import postList from '../content/posts.json';
import { Helmet } from 'react-helmet';

const Musings: React.FC = () => {
  function calculateReadingTime(text: string): string {
    // Assuming an average reading speed of 200 words per minute
    const wordsPerMinute = 200;

    // Counting the number of words in the text
    const wordCount = text.split(/\s+/).length;

    // Calculating the reading time in minutes
    const readingTimeMinutes = Math.ceil(wordCount / wordsPerMinute);

    // Formatting the result
    const pluralize = readingTimeMinutes === 1 ? '' : 's';
    return `${readingTimeMinutes} minute${pluralize}`;
  }

  return (
    <div>
      <Helmet>
        <title>{'Musings | Ben Musoke-Lubega'}</title>
      </Helmet>
      <h1>Musings</h1>
      <div style={{ display: 'flex', flexDirection: 'column', rowGap: '1rem' }}>
        {postList
          .filter((post) => post.draft === 'false')
          .map((post) => (
            <div key={post.id} className="highlight-on-hover">
              <Link to={`/musings/${post.id}`} className="article-preview">
                <article>
                  <h2>{post.title}</h2>
                  <span>
                    <small>{post.date}</small>
                    <small> • </small>
                    <small style={{ color: '#B3F2D1' }}>{calculateReadingTime(post.content)}</small>
                  </span>
                </article>
              </Link>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Musings;
