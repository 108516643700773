import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { Helmet } from 'react-helmet';
import './App.css';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Bio from './pages/bio';
import Home from './pages/home';
import Musings from './pages/musings';
import Travel from './pages/travel';
import Random from './pages/random';
import Article from './components/Article';
import NotFound from './pages/not_found';

// Initialize GA4 with your measurement ID
ReactGA.initialize('G-PG6B4L3T4B'); // Replace with your actual GA4 measurement ID

// Hook to track time spent on page
function usePageTracking() {
  const location = useLocation();
  const [startTime, setStartTime] = React.useState(Date.now());

  useEffect(() => {
    const trackPageView = () => {
      ReactGA.send({ hitType: 'pageview', page: location.pathname });
      setStartTime(Date.now());
    };

    // Track page view when location changes
    trackPageView();

    // Track time spent when leaving the page
    return () => {
      const timeSpent = Math.round((Date.now() - startTime) / 1000);
      ReactGA.event({
        category: 'User Engagement',
        action: 'Time Spent',
        label: location.pathname,
        value: timeSpent,
      });
    };
  }, [location, startTime]);

  // Track external link clicks
  useEffect(() => {
    const handleLinkClick = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      const link = target.closest('a');
      if (link && link.href && link.hostname !== window.location.hostname) {
        ReactGA.event({
          category: 'Outbound Link',
          action: 'click',
          label: link.href,
        });
      }
    };

    document.addEventListener('click', handleLinkClick);
    return () => document.removeEventListener('click', handleLinkClick);
  }, []);
}

// Component to track route changes and time
function RouteTracker() {
  usePageTracking();
  return null;
}

function App() {
  return (
    <div className="App">
      <Helmet>
        <title>Ben ML - Portfolio</title>
        <meta
          name="description"
          content="Personal portfolio and blog of Ben ML - Software Engineer, Traveler, and Tech Enthusiast"
        />
        <meta name="keywords" content="software engineer, web development, travel, tech blog" />
        <meta property="og:title" content="Ben ML - Portfolio" />
        <meta
          property="og:description"
          content="Personal portfolio and blog of Ben ML - Software Engineer, Traveler, and Tech Enthusiast"
        />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <link rel="canonical" href="https://benml.com" />
      </Helmet>
      <Router>
        <RouteTracker />
        <header className="App-header">
          <Navbar />
          <Routes>
            <Route
              path="/"
              element={
                <div className="centered-container">
                  {' '}
                  <Home />{' '}
                </div>
              }
            />
            <Route
              path="/bio"
              element={
                <div className="left-container">
                  {' '}
                  <Bio />{' '}
                </div>
              }
            />
            <Route
              path="/musings"
              element={
                <div className="left-container">
                  {' '}
                  <Musings />{' '}
                </div>
              }
            />
            <Route
              path="/musings/:postId/*"
              element={
                <div className="left-container">
                  {' '}
                  <Article match={{ params: { postId: '' } }} />{' '}
                </div>
              }
            />
            <Route
              path="/travel"
              element={
                <div className="left-container">
                  {' '}
                  <Travel />{' '}
                </div>
              }
            />
            <Route
              path="/random"
              element={
                <div className="left-container">
                  {' '}
                  <Random />{' '}
                </div>
              }
            />
            <Route
              path="*"
              element={
                <div className="left-container">
                  {' '}
                  <NotFound />{' '}
                </div>
              }
            />
          </Routes>
          <Footer />
        </header>
      </Router>
    </div>
  );
}

export default App;
